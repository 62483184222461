import React, { useEffect, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { Router } from "react-router-dom";
import Social_Nav from "./pages/socialNav";
import { AwesomeButton } from "react-awesome-button";
import CoverPage from "./pages/coverpage";
import LoadingPage1 from "./pages/loadingPage1";
import LoadingPage2 from "./pages/loadingPage2";
const MyFooter = () => {
  const colors = ["#2bb5a5", "#d41279", "#4ad6c6", "#7a8288"];
  const [currentColor, setCurrentColor] = useState(colors[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColor((prevColor) => {
        const currentIndex = colors.indexOf(prevColor);
        return colors[(currentIndex + 1) % colors.length];
      });
    }, 1000); // Change color every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, [colors]);
  return (
    <div className="footer">
      <marquee behavior="scroll">
        <h3 className="marquee_content">
          {Array(20)
            .fill(
              "Where Crypto meets Kindness | Meme with Purpose | Meme For Good | "
            )
            .map((text, index) => (
              <span key={index}>{text}</span>
            ))}
        </h3>
      </marquee>
    </div>
  );
};
const FirstBook = () => {
  const flipBook = useRef();
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState(false);
  const nextButtonClick = () => {
    flipBook.current.pageFlip().flipNext();
    setPage(flipBook.current.pageFlip().pages.currentPageIndex);
    // flipBook.current.pageFlip().turnToPage(3);
    // flipBook.current.pageFlip().flip(10, "top");
  };
  const prevButtonClick = () => {
    flipBook.current.pageFlip().flipPrev();
    setPage(flipBook.current.pageFlip().pages.currentPageIndex);
  };

  const onPage = (e) => {
    setPage(e.data);
  };

  useEffect(() => {}, [flipBook]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      nextButtonClick();
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [page]);
  const [isShrinkNav, setIsShrinkNav] = useState(false);

  // create an event listener
  useEffect(() => {
    //choose the screen size
    const handleResize = () => {
      if (window.innerWidth < 1300) {
        setIsShrinkNav(true);
      } else {
        setIsShrinkNav(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);
  const navcolors = ["#d41279", "#4ad6c6", "#2bb5a5", "#ffffff"];
  const [navcurrentColor, setDexCurrentColor] = useState(navcolors[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDexCurrentColor((prevColor) => {
        const currentIndex = navcolors.indexOf(prevColor);
        return navcolors[(currentIndex + 1) % navcolors.length];
      });
    }, 500); // Change color every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, [navcolors]);
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <nav className="navbar">
          <div
            className="display_flex_center"
            style={{
              height: "100%",
              width: "80%",
              margin: "auto",
              justifyContent: isShrinkNav ? "space-between" : "center ",
            }}
          >
            <div
              style={{
                // margin: isShrinkNav ? "" : "auto",
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div className={`menu ${isOpen ? "open" : ""}`}>
                  <ul style={{ display: isShrinkNav ? "" : "flex" }}>
                    <div
                      className="comic_dropdown"
                      style={{ position: "relative" }}
                    >
                      <AwesomeButton
                        className="nav_button_padding"
                        type="primary"
                        style={{
                          fontFamily: "KaoriGelBold",
                          paddingRight: "2px",
                          paddingLeft: "2px",
                        }}
                        onPress={() => {
                          flipBook.current.pageFlip().flip(0, "top");
                          if (isShrinkNav) {
                            toggleMenu();
                          }
                        }}
                      >
                        Home
                      </AwesomeButton>
                      <div className="dropdown">
                        <div className="dropdown-content">
                          <div>
                            <AwesomeButton
                              className="nav_button_padding"
                              type="secondary"
                              style={{
                                fontFamily: "KaoriGelBold",
                                paddingTop: "10px",
                                paddingRight: "2px",
                                paddingLeft: "2px",
                              }}
                              onPress={() => {
                                flipBook.current.pageFlip().flip(2, "top");
                                if (isShrinkNav) {
                                  toggleMenu();
                                }
                              }}
                            >
                              AboutUs
                            </AwesomeButton>
                          </div>
                          <div>
                            <AwesomeButton
                              className="nav_button_padding"
                              type="secondary"
                              style={{
                                fontFamily: "KaoriGelBold",
                                paddingTop: "10px",
                                paddingRight: "2px",
                                paddingLeft: "2px",
                              }}
                              onPress={() => {
                                flipBook.current.pageFlip().flip(14, "top");
                                if (isShrinkNav) {
                                  toggleMenu();
                                }
                              }}
                            >
                              OurTeam
                            </AwesomeButton>
                          </div>
                          <div>
                            <AwesomeButton
                              className="nav_button_padding"
                              type="secondary"
                              style={{
                                fontFamily: "KaoriGelBold",
                                paddingTop: "10px",
                                paddingRight: "2px",
                                paddingLeft: "2px",
                              }}
                              onPress={() => {
                                flipBook.current.pageFlip().flip(16, "top");
                                if (isShrinkNav) {
                                  toggleMenu();
                                }
                              }}
                            >
                              ContactUs
                            </AwesomeButton>
                          </div>

                          <a></a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="comic_dropdown"
                      style={{ position: "relative" }}
                    >
                      <AwesomeButton
                        className="nav_button_padding"
                        type="primary"
                        onPress={() => {
                          flipBook.current.pageFlip().flip(20, "top");
                        }}
                      >
                        Comic
                      </AwesomeButton>
                      {/* <div className="dropdown">
                        <div className="dropdown-content">
                          <div>
                            <AwesomeButton
                              className="nav_button_padding"
                              type="secondary"
                              style={{
                                fontFamily: "KaoriGelBold",
                                paddingTop: "10px",
                                paddingRight: "2px",
                                paddingLeft: "2px",
                              }}
                              onPress={() => {
                                flipBook.current.pageFlip().flip(4, "top");
                                if (isShrinkNav) {
                                  toggleMenu();
                                }
                              }}
                            >
                              Chapter1
                            </AwesomeButton>
                          </div>
                          <div>
                            <AwesomeButton
                              className="nav_button_padding"
                              type="secondary"
                              style={{
                                fontFamily: "KaoriGelBold",
                                paddingTop: "10px",
                                paddingRight: "2px",
                                paddingLeft: "2px",
                              }}
                              onPress={() => {
                                flipBook.current.pageFlip().flip(18, "top");
                                if (isShrinkNav) {
                                  toggleMenu();
                                }
                              }}
                            >
                              Chapter2
                            </AwesomeButton>
                          </div>
                          <div>
                            {" "}
                            <AwesomeButton
                              className="nav_button_padding"
                              type="secondary"
                              style={{
                                fontFamily: "KaoriGelBold",
                                paddingTop: "10px",
                                paddingRight: "2px",
                                paddingLeft: "2px",
                              }}
                              onPress={() => {
                                flipBook.current.pageFlip().flip(18, "top");
                                if (isShrinkNav) {
                                  toggleMenu();
                                }
                              }}
                            >
                              Chapter3
                            </AwesomeButton>
                          </div>

                          <a></a>
                        </div>
                      </div> */}
                    </div>

                    <AwesomeButton
                      className="nav_button_padding"
                      type="primary"
                      style={{
                        fontFamily: "KaoriGelBold",
                        paddingRight: "2px",
                        paddingLeft: "2px",
                      }}
                      onPress={() => {
                        flipBook.current.pageFlip().flip(4, "top");
                        if (isShrinkNav) {
                          toggleMenu();
                        }
                      }}
                    >
                      Tokenomics
                    </AwesomeButton>
                    <AwesomeButton
                      className="nav_button_padding"
                      type="primary"
                      style={{
                        fontFamily: "KaoriGelBold",
                        paddingRight: "2px",
                        paddingLeft: "2px",
                      }}
                      onPress={() => {
                        flipBook.current.pageFlip().flip(6, "top");
                        if (isShrinkNav) {
                          toggleMenu();
                        }
                      }}
                    >
                      RoadMap
                    </AwesomeButton>
                    <AwesomeButton
                      className="nav_button_padding"
                      type="primary"
                      style={{
                        fontFamily: "KaoriGelBold",
                        paddingRight: "2px",
                        paddingLeft: "2px",
                      }}
                      onPress={() => {
                        flipBook.current.pageFlip().flip(10, "top");
                        if (isShrinkNav) {
                          toggleMenu();
                        }
                      }}
                    >
                      Gallery
                    </AwesomeButton>
                    <AwesomeButton
                      className="nav_button_padding"
                      type="primary"
                      style={{
                        fontFamily: "KaoriGelBold",
                        paddingRight: "2px",
                        paddingLeft: "2px",
                      }}
                      onPress={() => {
                        flipBook.current.pageFlip().flip(20, "top");
                        if (isShrinkNav) {
                          toggleMenu();
                        }
                      }}
                    >
                      Learn
                    </AwesomeButton>
                    <AwesomeButton
                      className="nav_button_padding"
                      type="primary"
                      style={{
                        fontFamily: "KaoriGelBold",
                        paddingRight: "2px",
                        paddingLeft: "2px",
                      }}
                      onPress={() => {
                        flipBook.current.pageFlip().flip(20, "top");
                        if (isShrinkNav) {
                          toggleMenu();
                        }
                      }}
                    >
                      Merch
                    </AwesomeButton>

                    <AwesomeButton
                      className="nav_button_padding"
                      type="primary"
                      style={{
                        fontFamily: "KaoriGelBold",
                        paddingRight: "2px",
                        paddingLeft: "2px",
                      }}
                      onPress={() => {
                        flipBook.current.pageFlip().flip(16, "top");
                        nextButtonClick();
                        // setCurrentPage(40);
                        // setCurrentPage(39);
                        if (isShrinkNav) {
                          toggleMenu();
                        }
                      }}
                    >
                      PFPmaker
                    </AwesomeButton>
                    <AwesomeButton
                      className="nav_button_padding"
                      type="primary"
                      style={{
                        fontFamily: "KaoriGelBold",
                        paddingRight: "2px",
                        paddingLeft: "2px",
                      }}
                    >
                      <a
                        href="https://www.dextools.io/app/en/solana/pair-explorer/9fDEGZKgVvLEeQJGPyC9iacqYS9nhG1ZQRBsCDm9sCvu?t=1716340469947"
                        target="_blank"
                        style={{ color: "#e3e300" }}
                      >
                        DexTools
                      </a>
                    </AwesomeButton>

                    <li
                      style={{ display: "flex", justifyContent: "center" }}
                    ></li>
                  </ul>
                </div>
                <div
                  className="menu-toggle"
                  onClick={toggleMenu}
                  style={{ paddingRight: "2vw" }}
                >
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </div>
              </div>
            </div>
            <div className="ikigai_logo">
              <a href="/">
                <img
                  decoding="async"
                  width=""
                  height="100%"
                  src="/assets/logo2.gif"
                ></img>
              </a>
            </div>
          </div>
        </nav>
      </div>
      <div
        className="display_flex_center main_element"
        style={{ position: "relative" }}
      >
        <div className="container" style={{ visibility: "hidden" }}>
          <div>
            <button
              className="pagination_button"
              type="button"
              onClick={() => {
                prevButtonClick();
              }}
            >
              <svg
                className="pagination"
                xmlns="http://www.w3.org/2000/svg"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
                imageRendering="optimizeQuality"
                fillRule="evenodd"
                clipRule="evenodd"
                viewBox="0 0 299 511.517"
              >
                <path
                  fillRule="nonzero"
                  d="M276.464 434.711c10.425 18.421 12.316 32.942 9.096 42.797-1.308 4.005-3.498 7.156-6.269 9.362-2.766 2.203-6.17 3.539-9.905 3.91-8.047.798-17.633-2.724-26.385-11.38-.22-.218-.448-.424-.682-.618L42.055 286.874l.017-.017c-13.985-13.985-20.978-23.152-20.978-30.896 0-7.745 6.993-16.912 20.978-30.897.125-.125.246-.252.364-.382L242.982 32.501l.019.02c8.752-8.657 18.338-12.178 26.385-11.379 3.735.37 7.139 1.707 9.906 3.909 2.77 2.206 4.96 5.357 6.268 9.362 3.24 9.916 1.307 24.555-9.289 43.139l-.116.21-.008-.004-93.338 173.217a10.518 10.518 0 00.076 10.111l93.262 173.077c.101.188.207.37.317.548zm29.119 49.307c4.885-14.956 2.881-35.47-10.804-59.648l-90.744-168.409 90.652-168.233-.006-.003c13.774-24.254 15.799-44.83 10.902-59.822-2.643-8.091-7.258-14.608-13.19-19.332-5.936-4.726-13.135-7.583-20.947-8.358-13.938-1.384-29.767 3.95-43.277 17.312l.019.018-200.31 191.952c-.247.206-.487.424-.719.656C9.053 228.256 0 241.397 0 255.961c0 14.563 9.053 27.704 27.159 45.809l.019-.019 200.586 192.22c.13.145.265.286.405.425 13.509 13.362 29.339 18.696 43.277 17.313 7.812-.777 15.011-3.632 20.947-8.359 5.932-4.724 10.547-11.241 13.19-19.332z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="" style={{ width: "1128px" }}>
          <div className="demo-cover_book">
            <HTMLFlipBook
              width={564}
              height={705}
              size="stretch"
              minWidth={300}
              maxWidth={1200}
              minHeight={350}
              maxHeight={600}
              autoSize={true}
              showCover={true}
              drawShadow={false}
              // maxShadowOpacity={0.2}
              mobileScrollSupport={true}
              ref={flipBook}
              useMouseEvents={false}
              onFlip={() => {}}
            >
              <CoverPage number={0} />
              <LoadingPage1 number={1} />
              <LoadingPage2 number={2} />
            </HTMLFlipBook>
          </div>
        </div>
        <div className="container" style={{ visibility: "hidden" }}>
          <div>
            <button
              className="pagination_button"
              type="button"
              onClick={() => {
                nextButtonClick();
              }}
            >
              <svg
                className="pagination"
                xmlns="http://www.w3.org/2000/svg"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
                imageRendering="optimizeQuality"
                fillRule="evenodd"
                clipRule="evenodd"
                viewBox="0 0 299 511.517"
              >
                <path d="M12.579 75.262C-24.314 10.56 28.16-20.832 64.505 15.117L273.92 215.793c33.44 33.44 33.44 46.491 0 79.93L64.505 496.4c-36.345 35.949-88.819 4.557-51.926-60.146l97.261-180.496L12.579 75.262z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "rgb(255 255 255 / 30%)", zIndex: "1" }}>
        <MyFooter />
      </div>
    </div>
  );
};

export default FirstBook;
