import React, { useEffect, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import Page1 from "./pages/page1";
import Page2 from "./pages/page2";
import Page3 from "./pages/page3";
import Page4 from "./pages/page4";
import Page6 from "./pages/roadMapRight";
import Page7 from "./pages/page7";
import ComicPage1 from "./pages/comicPage1";
import ComicPage2 from "./pages/comicPage2";
import ComicPage3 from "./pages/comicPage3";
import ComicPage4 from "./pages/comicPage4";
import ComicPage5 from "./pages/comicPage5";
import ComicPage6 from "./pages/comicPage6";
import ComicPage7 from "./pages/comicPage7";
import ComicPage8 from "./pages/comicPage8";
import ComicPage9 from "./pages/comicPage9";
import ComicPage10 from "./pages/comicPage10";
import ComicPage11 from "./pages/comicPage11";
import ComicPage12 from "./pages/comicPage12";
import ComicPage13 from "./pages/comicPage13";
import Page5 from "./pages/roadMapLeft";
import Page8 from "./pages/page8";
import Page9 from "./pages/animation1";
import Page10 from "./pages/galleryImage1";
import Page12 from "./pages/page12";
import Page13 from "./pages/page13";
import Page15 from "./pages/page15";
import Page16 from "./pages/page16";
import BlankPageRight from "./pages/blackPageRight";
import ComicPage1_2 from "./pages/comicPage1_2";
import ComicPage2_2 from "./pages/comicPage2_2";
import ComicPage3_2 from "./pages/comicPage3_2";
import ComicPage4_2 from "./pages/comicPage4_2";
import ComicPage5_2 from "./pages/comicPage5_2";
import ComicPage6_2 from "./pages/comicPage6_2";
import ComicPage7_2 from "./pages/comicPage7_2";
import ComicPage8_2 from "./pages/comicPage8_2";
import ComicPage9_2 from "./pages/comicPage9_2";
import ComicPage10_2 from "./pages/comicPage10_2";
import ComicPage11_2 from "./pages/comicPage11_2";
import ComicPage12_2 from "./pages/comicPage12_2";
import ComicPage1_3 from "./pages/comicPage1_3";
import ComicPage2_3 from "./pages/comicPage2_3";
import ComicPage3_3 from "./pages/comicPage3_3";
import ComicPage4_3 from "./pages/comicPage4_3";
import ComicPage5_3 from "./pages/comicPage5_3";
import ComicPage6_3 from "./pages/comicPage6_3";
import ComicPage7_3 from "./pages/comicPage7_3";
import ComicPage8_3 from "./pages/comicPage8_3";
import ComicPage9_3 from "./pages/comicPage9_3";
import ComicPage10_3 from "./pages/comicPage10_3";
import ComicPage11_3 from "./pages/comicPage11_3";
import ComicPage12_3 from "./pages/comicPage12_3";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import "react-awesome-button/dist/themes/theme-blue.css";
import OurTeamLeft from "./pages/our_team_left";
import HomeLeft from "./pages/home_left";
import Animation1 from "./pages/animation1";
import GalleryPage1 from "./pages/galleryImage1";
import Animation2 from "./pages/animation2";
import GalleryPage2 from "./pages/galleryImage2";
import ComicBlankLeft from "./pages/comicBlankLeft";
import ComicBlankRight from "./pages/comicBlankRight";
import BlankRight from "./pages/blankRight";
import RoadMapLeft from "./pages/roadMapLeft";
import RoadMapRight from "./pages/roadMapRight";
import AboutUsLeft from "./pages/aboutUsLeft";
import ContactUs from "./pages/contactUs";
import JumpingLeft from "./pages/jumpingLeft";
import LearnBlankRight from "./pages/learnBlankRight";
import MerchBlankRight from "./pages/merchBlankRight";
const MyFooter = () => {
  return (
    <div className="footer">
      <marquee behavior="scroll">
        <h3 className="marquee_content">
          {Array(30)
            .fill(
              "Where Crypto meets Kindness | Meme with Purpose | Meme For Good | "
            )
            .map((text, index) => (
              <span key={index}>{text}</span>
            ))}
        </h3>
      </marquee>
    </div>
  );
};
const MainBook = () => {
  const handleAudio = () => {
    const audio = new Audio("/assets/page-flip-10.mp3"); // Adjust the path as necessary
    audio.play();
  };
  const flipBook = useRef();
  const hamburgerMenuButton = useRef();
  const [currentPage, setCurrentPage] = useState(40);
  const [status, setStatus] = useState(false);
  const nextButtonClick = () => {
    flipBook.current.pageFlip().flipNext();
    setCurrentPage(flipBook.current.pageFlip().pages.currentPageIndex);
    // flipBook.current.pageFlip().turnToPage(3);
    // flipBook.current.pageFlip().flip(10, "top");
  };
  const prevButtonClick = () => {
    flipBook.current.pageFlip().flipPrev();
    setCurrentPage(flipBook.current.pageFlip().pages.currentPageIndex);
  };

  // useEffect(() => {}, [flipBook]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    // setIsOpen(!isOpen);
    if (hamburgerMenuButton.current) {
      hamburgerMenuButton.current.style.display =
        hamburgerMenuButton.current.style.display === "none" ? "block" : "none";
    }
  };
  const [isShrinkNav, setIsShrinkNav] = useState(false);

  // create an event listener
  useEffect(() => {
    //choose the screen size
    const handleResize = () => {
      if (window.innerWidth < 1400) {
        setIsShrinkNav(true);
      } else {
        setIsShrinkNav(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    console.log(isMobile);
  }, [isMobile]);

  // create an event listener
  useEffect(() => {
    //choose the screen size
    const handleResize = () => {
      if (window.innerWidth < 792) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = () => {
    setIsPlaying(true);
    setTimeout(() => {
      setIsPlaying(false);
    }, 1800); // Hide after 1 seconds
  };
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <nav className="navbar">
          <div
            className="display_flex_center"
            style={{
              height: "100%",
              width: "80%",
              margin: "auto",
              justifyContent: isShrinkNav ? "space-between" : "center ",
            }}
          >
            <div
              style={{
                // margin: isShrinkNav ? "" : "auto",
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div
                  ref={hamburgerMenuButton}
                  style={{ display: isShrinkNav ? "none" : "" }}
                  className={isShrinkNav ? "menu open" : "menu "}
                >
                  <ul style={{ display: isShrinkNav ? "" : "flex" }}>
                    <div
                      className="comic_dropdown"
                      style={{ position: "relative" }}
                    >
                      <AwesomeButton
                        className="nav_button_padding"
                        type="primary"
                        style={{
                          fontFamily: "KaoriGelBold",
                          paddingRight: "2px",
                          paddingLeft: "2px",
                        }}
                        onPress={() => {
                          flipBook.current.pageFlip().flip(1, "top");
                          if (isShrinkNav) {
                            toggleMenu();
                          }
                        }}
                      >
                        Home
                      </AwesomeButton>
                      <div className="dropdown">
                        <div className="dropdown-content">
                          <div>
                            <AwesomeButton
                              className="nav_button_padding"
                              type="secondary"
                              style={{
                                fontFamily: "KaoriGelBold",
                                paddingTop: "10px",
                                paddingRight: "2px",
                                paddingLeft: "2px",
                              }}
                              onPress={() => {
                                flipBook.current.pageFlip().flip(3, "top");
                                if (isShrinkNav) {
                                  toggleMenu();
                                }
                              }}
                            >
                              AboutUs
                            </AwesomeButton>
                          </div>
                          <div>
                            <AwesomeButton
                              className="nav_button_padding"
                              type="secondary"
                              style={{
                                fontFamily: "KaoriGelBold",
                                paddingTop: "10px",
                                paddingRight: "2px",
                                paddingLeft: "2px",
                              }}
                              onPress={() => {
                                flipBook.current.pageFlip().flip(51, "top");
                                if (isShrinkNav) {
                                  toggleMenu();
                                }
                              }}
                            >
                              OurTeam
                            </AwesomeButton>
                          </div>
                          <div>
                            <AwesomeButton
                              className="nav_button_padding"
                              type="secondary"
                              style={{
                                fontFamily: "KaoriGelBold",
                                paddingTop: "10px",
                                paddingRight: "2px",
                                paddingLeft: "2px",
                              }}
                              onPress={() => {
                                flipBook.current.pageFlip().flip(52, "top");
                                if (isShrinkNav) {
                                  toggleMenu();
                                }
                              }}
                            >
                              ContactUs
                            </AwesomeButton>
                          </div>

                          <a></a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="comic_dropdown"
                      style={{ position: "relative" }}
                    >
                      <AwesomeButton
                        className="nav_button_padding"
                        type="primary"
                        onPress={() => {
                          flipBook.current.pageFlip().flip(4, "top");
                        }}
                      >
                        Comic
                      </AwesomeButton>
                      <div className="dropdown">
                        <div className="dropdown-content">
                          <div>
                            <AwesomeButton
                              className="nav_button_padding"
                              type="secondary"
                              style={{
                                fontFamily: "KaoriGelBold",
                                paddingTop: "10px",
                                paddingRight: "2px",
                                paddingLeft: "2px",
                              }}
                              onPress={() => {
                                flipBook.current.pageFlip().flip(30, "top");
                                if (isShrinkNav) {
                                  toggleMenu();
                                }
                              }}
                            >
                              <p>Chapter3</p>
                              <p className="color-change">new</p>
                            </AwesomeButton>
                          </div>
                          <div>
                            <AwesomeButton
                              className="nav_button_padding"
                              type="secondary"
                              style={{
                                fontFamily: "KaoriGelBold",
                                paddingTop: "10px",
                                paddingRight: "2px",
                                paddingLeft: "2px",
                              }}
                              onPress={() => {
                                flipBook.current.pageFlip().flip(18, "top");
                                if (isShrinkNav) {
                                  toggleMenu();
                                }
                              }}
                            >
                              Chapter2
                            </AwesomeButton>
                          </div>
                          <div>
                            {" "}
                            <AwesomeButton
                              className="nav_button_padding"
                              type="secondary"
                              style={{
                                fontFamily: "KaoriGelBold",
                                paddingTop: "10px",
                                paddingRight: "2px",
                                paddingLeft: "2px",
                              }}
                              onPress={() => {
                                flipBook.current.pageFlip().flip(5, "top");
                                if (isShrinkNav) {
                                  toggleMenu();
                                }
                              }}
                            >
                              Chapter1
                            </AwesomeButton>
                          </div>

                          <a></a>
                        </div>
                      </div>
                    </div>

                    <AwesomeButton
                      className="nav_button_padding"
                      type="primary"
                      style={{
                        fontFamily: "KaoriGelBold",
                        paddingRight: "2px",
                        paddingLeft: "2px",
                      }}
                      onPress={() => {
                        flipBook.current.pageFlip().flip(42, "top");
                        if (isShrinkNav) {
                          toggleMenu();
                        }
                      }}
                    >
                      Tokenomics
                    </AwesomeButton>
                    <AwesomeButton
                      className="nav_button_padding"
                      type="primary"
                      style={{
                        fontFamily: "KaoriGelBold",
                        paddingRight: "2px",
                        paddingLeft: "2px",
                      }}
                      onPress={() => {
                        flipBook.current.pageFlip().flip(44, "top");
                        if (isShrinkNav) {
                          toggleMenu();
                        }
                      }}
                    >
                      RoadMap
                    </AwesomeButton>
                    <AwesomeButton
                      className="nav_button_padding"
                      type="primary"
                      style={{
                        fontFamily: "KaoriGelBold",
                        paddingRight: "2px",
                        paddingLeft: "2px",
                      }}
                      onPress={() => {
                        flipBook.current.pageFlip().flip(46, "top");
                        if (isShrinkNav) {
                          toggleMenu();
                        }
                      }}
                    >
                      Gallery
                    </AwesomeButton>
                    <AwesomeButton
                      className="nav_button_padding"
                      type="primary"
                      style={{
                        fontFamily: "KaoriGelBold",
                        paddingRight: "2px",
                        paddingLeft: "2px",
                      }}
                      onPress={() => {
                        flipBook.current.pageFlip().flip(57, "top");
                        if (isShrinkNav) {
                          toggleMenu();
                        }
                      }}
                    >
                      Learn
                    </AwesomeButton>
                    <AwesomeButton
                      className="nav_button_padding"
                      type="primary"
                      style={{
                        fontFamily: "KaoriGelBold",
                        paddingRight: "2px",
                        paddingLeft: "2px",
                      }}
                      onPress={() => {
                        flipBook.current.pageFlip().flip(59, "top");
                        if (isShrinkNav) {
                          toggleMenu();
                        }
                      }}
                    >
                      Merch
                    </AwesomeButton>

                    <AwesomeButton
                      className="nav_button_padding"
                      type="primary"
                      style={{
                        fontFamily: "KaoriGelBold",
                        paddingRight: "2px",
                        paddingLeft: "2px",
                      }}
                      onPress={() => {
                        flipBook.current.pageFlip().flip(52, "top");
                        nextButtonClick();
                        // setCurrentPage(40);
                        // setCurrentPage(39);
                        if (isShrinkNav) {
                          toggleMenu();
                        }
                      }}
                    >
                      PFPmaker
                    </AwesomeButton>
                    <AwesomeButton
                      className="nav_button_padding"
                      type="primary"
                      style={{
                        fontFamily: "KaoriGelBold",
                        paddingRight: "2px",
                        paddingLeft: "2px",
                      }}
                    >
                      <a
                        href="https://www.dextools.io/app/en/solana/pair-explorer/9fDEGZKgVvLEeQJGPyC9iacqYS9nhG1ZQRBsCDm9sCvu?t=1716340469947"
                        target="_blank"
                        style={{ color: "#e3e300" }}
                      >
                        DexTools
                      </a>
                    </AwesomeButton>

                    <li
                      style={{ display: "flex", justifyContent: "center" }}
                    ></li>
                  </ul>
                </div>
                <div
                  className="menu-toggle"
                  onClick={toggleMenu}
                  style={{ paddingRight: "2vw" }}
                >
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </div>
              </div>
            </div>
            <div className="ikigai_logo">
              <a href="/">
                <img
                  decoding="async"
                  width=""
                  height="100%"
                  src="/assets/logo2.gif"
                ></img>
              </a>
            </div>
          </div>
        </nav>
      </div>
      <div
        className="display_flex_center main_element"
        style={{ position: "relative" }}
      >
        <div className="book-cover" style={{ position: "relative" }}>
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <div className="demo-book">
              <HTMLFlipBook
                // style={{ width: "100%", height: "100%" }}
                width={500}
                height={600}
                size="stretch"
                minWidth={300}
                maxWidth={1200}
                // showCover={true}
                minHeight={350}
                maxHeight={600}
                // autoSize={true}
                // drawShadow={false}
                maxShadowOpacity={0.2}
                mobileScrollSupport={true}
                ref={flipBook}
                useMouseEvents={false}
                showPageCorners={false}
                onFlip={(e) => {
                  console.log(e);
                }}
              >
                <HomeLeft />
                <Page1 />
                <AboutUsLeft />
                <Page2 />
                <ComicBlankLeft />
                <ComicPage1 />
                <ComicPage2 />
                <ComicPage3 />
                <ComicPage4 />
                <ComicPage5 />
                <ComicPage6 />
                <ComicPage7 />
                <ComicPage8 />
                <ComicPage9 />
                <ComicPage10 />
                <ComicPage11 />
                <ComicPage12 />
                <ComicPage13 />
                <ComicPage1_2 />
                <ComicPage2_2 />
                <ComicPage3_2 />
                <ComicPage4_2 />
                <ComicPage5_2 />
                <ComicPage6_2 />
                <ComicPage7_2 />
                <ComicPage8_2 />
                <ComicPage9_2 />
                <ComicPage10_2 />
                <ComicPage11_2 />
                <ComicPage12_2 />
                <ComicPage1_3 />
                <ComicPage2_3 />
                <ComicPage3_3 />
                <ComicPage4_3 />
                <ComicPage5_3 />
                <ComicPage6_3 />
                <ComicPage7_3 />
                <ComicPage8_3 />
                <ComicPage9_3 />
                <ComicPage10_3 />
                <ComicPage11_3 />
                <ComicPage12_3 />
                <Page3 />
                <Page4 />
                <RoadMapLeft />
                <RoadMapRight />
                <Animation1 />
                <GalleryPage1 />
                <Animation2 />
                <GalleryPage2 />
                <OurTeamLeft />
                <Page12 />
                <Page13 />
                <ContactUs />
                <Page15 pageNumber={currentPage} />
                <Page16 pageNumber={currentPage} />
                <JumpingLeft />
                <LearnBlankRight />
                <JumpingLeft />
                <MerchBlankRight />
              </HTMLFlipBook>
            </div>
            <div
              style={{
                width: "65px",
                position: "absolute",
                right: "-80px",
                top: "239px",
                pointerEvents: "none",
                display: isShrinkNav ? "none" : "block",
              }}
            >
              <div
                className="display_flex_center"
                style={{
                  width: "100%",
                  height: "100%",
                  boxSizing: "border-box",
                }}
              >
                <img
                  alt=""
                  src="/assets/Character_Right_Sit_FinalGIF.gif"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div
              style={{
                width: "92px",
                position: "absolute",
                left: "0",
                top: "-75px",
                pointerEvents: "none",
              }}
            >
              <div
                className="display_flex_center"
                style={{
                  width: "100%",
                  height: "100%",
                  boxSizing: "border-box",
                }}
              >
                <img
                  alt=""
                  src="/assets/Character_Left_Shock_FinalGIF.gif"
                  style={{
                    width: "100%",
                    display: isPlaying ? "block" : "none",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: "92px",
                position: "absolute",
                left: "0",
                top: "-75px",
                pointerEvents: "none",
              }}
            >
              <div
                className="display_flex_center"
                style={{
                  width: "100%",
                  height: "100%",
                  boxSizing: "border-box",
                }}
              >
                <img
                  alt=""
                  src="/assets/Character_Left_Idle_FinalGIF.gif"
                  style={{
                    width: "100%",
                    display: isPlaying ? "none" : "block",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="container prev_button">
            <div>
              <button
                className="pagination_button"
                type="button"
                onClick={() => {
                  handleClick();
                  prevButtonClick();
                  if (flipBook.current.pageFlip().pages.currentPageIndex != 0) {
                    handleAudio();
                  }
                }}
              >
                <svg
                  className="pagination"
                  xmlns="http://www.w3.org/2000/svg"
                  shapeRendering="geometricPrecision"
                  textRendering="geometricPrecision"
                  imageRendering="optimizeQuality"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  viewBox="0 0 299 511.517"
                >
                  <path
                    fillRule="nonzero"
                    d="M276.464 434.711c10.425 18.421 12.316 32.942 9.096 42.797-1.308 4.005-3.498 7.156-6.269 9.362-2.766 2.203-6.17 3.539-9.905 3.91-8.047.798-17.633-2.724-26.385-11.38-.22-.218-.448-.424-.682-.618L42.055 286.874l.017-.017c-13.985-13.985-20.978-23.152-20.978-30.896 0-7.745 6.993-16.912 20.978-30.897.125-.125.246-.252.364-.382L242.982 32.501l.019.02c8.752-8.657 18.338-12.178 26.385-11.379 3.735.37 7.139 1.707 9.906 3.909 2.77 2.206 4.96 5.357 6.268 9.362 3.24 9.916 1.307 24.555-9.289 43.139l-.116.21-.008-.004-93.338 173.217a10.518 10.518 0 00.076 10.111l93.262 173.077c.101.188.207.37.317.548zm29.119 49.307c4.885-14.956 2.881-35.47-10.804-59.648l-90.744-168.409 90.652-168.233-.006-.003c13.774-24.254 15.799-44.83 10.902-59.822-2.643-8.091-7.258-14.608-13.19-19.332-5.936-4.726-13.135-7.583-20.947-8.358-13.938-1.384-29.767 3.95-43.277 17.312l.019.018-200.31 191.952c-.247.206-.487.424-.719.656C9.053 228.256 0 241.397 0 255.961c0 14.563 9.053 27.704 27.159 45.809l.019-.019 200.586 192.22c.13.145.265.286.405.425 13.509 13.362 29.339 18.696 43.277 17.313 7.812-.777 15.011-3.632 20.947-8.359 5.932-4.724 10.547-11.241 13.19-19.332z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="container next_button">
            <div>
              <button
                className="pagination_button"
                type="button"
                onClick={() => {
                  handleClick();
                  nextButtonClick();
                  handleAudio();
                }}
              >
                <svg
                  className="pagination"
                  xmlns="http://www.w3.org/2000/svg"
                  shape-rendering="geometricPrecision"
                  text-rendering="geometricPrecision"
                  image-rendering="optimizeQuality"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  viewBox="0 0 308 511.923"
                >
                  <path
                    fill-rule="nonzero"
                    d="M31.535 434.713c-10.425 18.42-12.315 32.941-9.096 42.796 1.309 4.006 3.499 7.156 6.269 9.362 2.767 2.203 6.171 3.539 9.905 3.91 8.047.798 17.634-2.724 26.386-11.38.22-.218.448-.424.682-.618l200.265-191.908-.018-.018c13.985-13.984 20.978-23.151 20.978-30.896 0-7.744-6.993-16.911-20.978-30.896a10.016 10.016 0 01-.363-.382L65.018 32.501l-.019.02c-8.752-8.657-18.338-12.178-26.386-11.379-3.734.37-7.138 1.707-9.905 3.909-2.77 2.206-4.96 5.357-6.269 9.362-3.239 9.916-1.307 24.555 9.29 43.139l.116.211.007-.005 93.339 173.218a10.518 10.518 0 01-.076 10.111L31.852 434.164c-.1.188-.207.37-.317.549zM2.417 484.019c-4.886-14.956-2.881-35.47 10.804-59.648l90.744-168.41L13.313 87.728l.006-.003C-.455 63.471-2.481 42.895 2.417 27.903c2.643-8.091 7.258-14.608 13.19-19.332C21.543 3.845 28.742.988 36.554.213c13.938-1.384 29.767 3.95 43.276 17.312l-.018.018 200.31 191.952c.247.206.488.424.72.656C298.947 228.256 308 241.398 308 255.961c0 14.563-9.053 27.705-27.158 45.81l-.019-.019L80.236 493.973a9.5 9.5 0 01-.406.424c-13.509 13.362-29.338 18.697-43.276 17.313-7.813-.776-15.011-3.632-20.947-8.359-5.932-4.724-10.547-11.241-13.19-19.332z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "rgb(255 255 255 / 30%)", zIndex: "1" }}>
        <MyFooter />
      </div>
    </div>
  );
};

export default MainBook;
